.nav-item {
  font-weight: 800;
}

.nav-link {
  color: rgba(255,255,255,.5);
  font-weight: 800;
  display: block;
  padding: .5em 1em;
}

.nav-link.active {
  color: white;
}

a[href]:hover {
  color: #edc531 !important;
}

body {
  height: 100vh;
  background-image: url('../images/scribble.png');
  font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
  color: #2f2f2f;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

#container {
  max-width: 1400px;
  background-color: white;
  border-radius: 0;
  height: 100%;
  padding: 0px;
  flex: 1;
}

.inner-container {
  background-color: white;
  align-content: center;
  padding: 20px;
}

.dark-nav {
  padding-right: 15px;
  padding-left: 15px;
  background-color: #343a40!important
}

.carousel-item > img { 
  width: 100%; 
}

.scenario{
  background-color: lightgrey;
  border: 3px solid black;
  padding: 15px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.card {
  width: 100%;
  height: 100%;
}

.content {
  width: 60%;
  height: 60%;
  text-align: center;
  margin-left:auto;
  margin-right:auto;
}

@media only screen and (max-width: 1200px) {
  .content {
      width: 80%;
      height: 80%;
  }
}


@media only screen and (max-width: 700px) {
  .content {
      width: 100%;
      height: 100%;
  }
}


.truth tr:first-child th{
  border-bottom: 1px solid black;
}

.tc-logo {
  padding-top: 20px;
  padding-bottom: 5px;
  max-width: 350px;
}

.mobilogo {
  max-height: 30px;
}

button {
  margin: 3px;
  padding: 0px;
}

.btn-warning {
  color: #4A4947;
  font-weight: bold;
  background-color: #E7CB1B;
  border-color: #E7CB1B;
}

.btn-warning:hover {
  color:rgb(58, 57, 56);
  background-color: rgb(199, 176, 27);
  border-color: rgb(199, 176, 27);
}

.btn-secondary {
  background-color: #4A4947;
  border-color: #4A4947;
}

.btn-secondary:hover {
  color: #E7CB1B;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='orange' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='orange' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}
