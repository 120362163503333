.RulesTable {
    text-align: left;
}

.rulesApplied{
    background-color: lightgrey;
    border: 3px solid black;
    padding: 15px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 480px) {
	.RulesTable {
        font-size: 11px;
	}
}

.popover{
    max-width: 100%;
}