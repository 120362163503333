.drag_things_to_boxes .component_box {
	float: left;
	width: 100%;
}

.drag_things_to_boxes {
	width: 100%;
}

.Table.th {
	width: 10%;
}

.drag_things_to_boxes .box {
	border: 2px solid black;
	background-color: #F6F6F6;
	border-radius: 4px;
	/* height: 55px; */
	width: 100%;
	margin: 10px 80px 0 0;
}

.drag_things_to_boxes .highlighted .box {
    box-shadow: inset 0 0 4px #00f;
}

img {
	float: right;
}

